/* Multi View Contrainer */
.info-view {
	height: calc(90vh - 23px);
	min-width: 300px;
	min-height: 500px;
	flex-grow: 0.10;
}
.tiny-info-view {
	height: 81px;
	min-width: 300px;
	/* display: none; */
}
.additional-info-view {
	/* Setting the width somehow fixes the issue where the toolbar does not become scrollable.... (in UserGroupTabs) */
	width: 400px;
}
.create-view {
	height: calc(90vh - 13px);
	min-width: 500px;
	min-height: 500px;
	flex-grow: 0.50;
}
.tiny-info-hidden {
	display: none;
}
.info-hidden {
	display: none;
}
.additional-info-hidden {
	display: none;
}
.additional-info-normal
{
	flex-grow: 0.90;
	height: calc(90vh - 23px);
}
.additional-info-shorter {
	flex-grow: 1;
	height: calc(90vh - 130px);
}

/* If its a normal-ish type screen */
@media only screen and (max-width: 1336px) {
	.info-view {
		height: 90vh;
		min-width: 300px;
		min-height: 500px;
		/* flex-grow: 0.30; */
	}
	.additional-info-normal
	{
		height: 90vh;
		flex-grow: 0.90;
	}
	.additional-info-shorter {
		flex-grow: 1;
		height: calc(90vh - 100px);
	}
}

/* If its a tablet-like screen */
@media only screen and (max-width: 1265px) {
	.info-view {
		height: calc(90vh - 10px);
		min-width: 350px;
		min-height: 500px;
		/* flex-grow: 0.50; */
	}
	.additional-info-normal
	{
		flex-grow: 0.90;
		height: calc(90vh - 10px);
	}
	.additional-info-shorter {
		flex-grow: 1;
		height: calc(90vh - 110px);
	}
}

/* If its a mobile-like screen */
@media only screen and (max-width: 950px) {
	.info-view {
		height: calc(90vh - 15px);
		min-width: 500px;
		min-height: 500px;
		flex-grow: 1;
	}
	.edit-view {
		height: calc(90vh - 13px);
		min-width: 500px;
		min-height: 500px;
		flex-grow: 1;
	}
	.create-view {
		height: calc(90vh - 13px);
		min-width: 500px;
		min-height: 500px;
		flex-grow: 1;
	}
	.container-padding {
		flex-grow: 0.20;
		display: none;
	}
}

/* Single View Container */
.single-view-root {
    display: flex;
    flex-direction: row;
}
.single-view-container {
	height: calc(90vh - 15px);
	width: 100%;
}

.single-view {
	height: calc(90vh - 13px);
	min-width: 500px;
	min-height: 500px;
	flex-grow: 0.60;
}

.single-view-container-padding {
	flex-grow: 0.25;
}
.tiny-info-header {
	margin: 8px 0px 0px 0px
}

.tiny-info-container {
	min-width: 300;
	width: 100%;
	margin: 5px;
	position: relative;
}

.tiny-info-section {
	display: inline-block;
	margin-left: 15px;
}

.tiny-info-content {
	margin-left: 15px;
}
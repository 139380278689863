:root {
  --pulse-scale: 0;
  /* --pulse-color: rgba(28, 105, 194, 0.7); */
  --pulse-color: rgba(28, 105, 194, 1);
  /* --pulse-color: white; */
  --pulse-offset: -30px;
  --pulse-radius: 60px;
  --pulse-fade: rgba(28, 105, 194, 0);
  /* --pulse-fade: rgba(28, 105, 194, 0.9); */
}

/* The side navigation menu */
.map-sidelist-closed {
  position: absolute;
  height: 100%;
  /* 100% Full-height */
  max-height: 100%;
  /* 100% Full-height */
  width: 0px;
  /* 0 width - change this with JavaScript */
  /* position: fixed; Stay in place */
  z-index: 3;
  /* Stay on top */
  top: 0;
  /* Stay at the top */
  right: 0;
  background-color: rgb(255, 255, 255);
  overflow-x: hidden;
  transition: 0.5s;
  /* 0.5 second transition effect to slide in the map-sidelist */
  border-radius: 5px;
}

.map-sidelist-opened {
  position: absolute;
  height: 100%;
  /* 100% Full-height */
  max-height: 100%;
  /* 100% Full-height */
  width: 400px;
  /* 0 width - change this with JavaScript */
  /* position: fixed; Stay in place */
  z-index: 3;
  /* Stay on top */
  top: 0;
  /* Stay at the top */
  right: 0;
  background-color: rgb(255, 255, 255);
  overflow-x: hidden;
  /* Disable horizontal scroll */
  transition: 0.5s;
  /* 0.5 second transition effect to slide in the map-sidelist */
  border-radius: 5px;
}

/* The navigation menu links */
.map-sidelist a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

/* When you mouse over the navigation links, change their color */
.map-sidelist a:hover {
  color: #f1f1f1;
}

/* Position and style the close button (top right corner) */
.map-sidelist .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

/* On smaller screens, where height is less than 450px, change the style of the map-sidelist (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
  .map-sidelist {
    padding-top: 15px;
  }

  .map-sidelist a {
    font-size: 18px;
  }
}

/* see LiveDriverMarker and pulseId */
/* https://trello.com/c/lsuk3s7Q */
.driver_location_online {
  background: transparent;

  /* border: 2px solid #ccc; */
  /* border: 0px solid transparent; */
  /* border: 2px solid blue; */
  /* border: 2px solid white; */

  /* circle */
  border-radius: 50%;

  margin-left: var(--pulse-offset);
  margin-top: var(--pulse-offset);
  width: var(--pulse-radius);
  height: var(--pulse-radius);

  animation: pulse 2s infinite;
  -moz-animation: pulse 2s infinite;
  -webkit-animation: pulse 2s infinite;
  z-index: 9 !important;
}

@-moz-keyframes pulse {
  from {
    -moz-transform: scale(var(--pulse-scale));
    box-shadow: 0 0 0 0 var(--pulse-color);
  }

  95% {
    -moz-transform: scale(1);
    box-shadow: 0 0 0 var(--pulse-radius) var(--pulse-fade);
  }

  to {
    -moz-transform: scale(var(--pulse-scale));
    box-shadow: 0 0 0 0 var(--pulse-fade);
  }
}

@keyframes pulse {
  0% {
    transform: scale(var(--pulse-scale));
    box-shadow: 0 0 0 0 var(--pulse-color);
  }

  95% {
    transform: scale(1);
    box-shadow: 0 0 0 var(--pulse-radius) var(--pulse-fade);
  }

  100% {
    transform: scale(var(--pulse-scale));
    box-shadow: 0 0 0 0 var(--pulse-fade);
  }
}

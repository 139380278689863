.user-group-info-view {
	height: calc(90vh - 13px);
	min-width: 500px;
	min-height: 500px;
	flex-grow: 0.30;
}
.user-group-info {
	flex-grow: 0.70;
}

/* If its a normal-ish type screen */
@media only screen and (max-width: 1336px) {
	.user-group-info-view {
		height: 90vh;
		min-width: 500px;
		min-height: 500px;
		flex-grow: 0.30;
	}
}

/* If its a tablet-like screen */
@media only screen and (max-width: 1265px) {
	.user-group-info-view {
		height: calc(90vh - 10px);
		min-width: 500px;
		min-height: 500px;
		flex-grow: 0.50;
	}
	.user-group-info {
		flex-grow: 0.50;
	}
}

/* If its a mobile-like screen */
@media only screen and (max-width: 950px) {
	.user-group-info-view {
		height: calc(90vh - 15px);
		min-width: 500px;
		min-height: 500px;
		flex-grow: 1;
	}
	.user-group-info {
		display: none;
	}
}
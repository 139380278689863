@import url(https://fonts.googleapis.com/css2?family=Exo:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap);
/* https://fonts.google.com/share?selection.family=Chilanka */
/* see App.js */
/* @import url('https://fonts.googleapis.com/css2?family=Chilanka&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Antic+Slab&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Comfortaa&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Lobster+Two:ital@1&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Cinzel+Decorative&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Gugi&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Sail&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Faster+One&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Tourney&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Lora&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Rubik+Puddles&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Grape+Nuts&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@500&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Poiret+One&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Ubuntu+Condensed&display=swap'); */

.user-table-container {
	height: 100%;
	min-height: 300;
	width: 100%;
}

.info-header {
	margin: 8px 0px 0px 0px
}

.center-profile-image {
	display: block;
	margin-left: auto;
	margin-right: auto;
} 

.border-profile-image {
	border: 3px solid #3498db;
	border-radius: 10%;
}

.size-profile-image {
	width: 150px;
	height: 150px;
}

.size-profile-icon {
    font-size: 150px !important;
}

.container {
    width: 157px;
    height: 157px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.basic-info-item-header {
    margin: 0px;
    color: grey;
    font-weight: normal;
}

.basic-info-item-data {
    margin-top: 1px;
    margin-bottom: 5px;
    font-weight: normal;
}


.tab-buttons {
	/* min-width: 50px; */
}

.first-tab-button {
	/* max-width: 100%;
	width: 100%; */
}

.tab-panel {
	margin: 15px;
	height: 70vh;
}

/* If its a normal-ish type screen */
@media only screen and (max-width: 1336px) {
	.tab-panel {
		height: 65vh;
	}
}

/* If its a tablet-like screen */
@media only screen and (max-width: 1265px) {
	.tab-panel {
		height: 60vh;
	}
}

/* If its a mobile-like screen */
@media only screen and (max-width: 800px) {
	.tab-panel {
		height: 55vh;
	}
}
.table-container {
	height: calc(100% - 160px);
	min-height: 300;
	width: 100%;
}
.table {
	height: 100%;
	min-height: 300;
	width: 100%;
}
.todo-please-replace-me {
	border: gainsboro;
	border-style: none;
	margin: 5px;
}

.tab-panel {
	margin: 15px;
	height: 70vh;
}

/* If its a normal-ish type screen */
@media only screen and (max-width: 1336px) {
	.tab-panel {
		height: 65vh;
	}
}

/* If its a tablet-like screen */
@media only screen and (max-width: 1265px) {
	.tab-panel {
		height: 60vh;
	}
}

/* If its a mobile-like screen */
@media only screen and (max-width: 800px) {
	.tab-panel {
		height: 55vh;
	}
}
:root {
  --pulse-scale: 0;
  /* --pulse-color: rgba(28, 105, 194, 0.7); */
  --pulse-color: rgba(28, 105, 194, 1);
  /* --pulse-color: white; */
  --pulse-offset: -30px;
  --pulse-radius: 60px;
  --pulse-fade: rgba(28, 105, 194, 0);
  /* --pulse-fade: rgba(28, 105, 194, 0.9); */
}

/* The side navigation menu */
.map-sidelist-closed {
  position: absolute;
  height: 100%;
  /* 100% Full-height */
  max-height: 100%;
  /* 100% Full-height */
  width: 0px;
  /* 0 width - change this with JavaScript */
  /* position: fixed; Stay in place */
  z-index: 3;
  /* Stay on top */
  top: 0;
  /* Stay at the top */
  right: 0;
  background-color: rgb(255, 255, 255);
  overflow-x: hidden;
  transition: 0.5s;
  /* 0.5 second transition effect to slide in the map-sidelist */
  border-radius: 5px;
}

.map-sidelist-opened {
  position: absolute;
  height: 100%;
  /* 100% Full-height */
  max-height: 100%;
  /* 100% Full-height */
  width: 400px;
  /* 0 width - change this with JavaScript */
  /* position: fixed; Stay in place */
  z-index: 3;
  /* Stay on top */
  top: 0;
  /* Stay at the top */
  right: 0;
  background-color: rgb(255, 255, 255);
  overflow-x: hidden;
  /* Disable horizontal scroll */
  transition: 0.5s;
  /* 0.5 second transition effect to slide in the map-sidelist */
  border-radius: 5px;
}

/* The navigation menu links */
.map-sidelist a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

/* When you mouse over the navigation links, change their color */
.map-sidelist a:hover {
  color: #f1f1f1;
}

/* Position and style the close button (top right corner) */
.map-sidelist .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

/* On smaller screens, where height is less than 450px, change the style of the map-sidelist (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
  .map-sidelist {
    padding-top: 15px;
  }

  .map-sidelist a {
    font-size: 18px;
  }
}

/* see LiveDriverMarker and pulseId */
/* https://trello.com/c/lsuk3s7Q */
.driver_location_online {
  background: transparent;

  /* border: 2px solid #ccc; */
  /* border: 0px solid transparent; */
  /* border: 2px solid blue; */
  /* border: 2px solid white; */

  /* circle */
  border-radius: 50%;

  margin-left: -30px;

  margin-left: var(--pulse-offset);
  margin-top: -30px;
  margin-top: var(--pulse-offset);
  width: 60px;
  width: var(--pulse-radius);
  height: 60px;
  height: var(--pulse-radius);

  animation: pulse 2s infinite;
  -moz-animation: pulse 2s infinite;
  -webkit-animation: pulse 2s infinite;
  z-index: 9 !important;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform: scale(var(--pulse-scale));
            transform: scale(var(--pulse-scale));
    box-shadow: 0 0 0 0 rgba(28, 105, 194, 1);
    box-shadow: 0 0 0 0 var(--pulse-color);
  }

  95% {
    -webkit-transform: scale(1);
            transform: scale(1);
    box-shadow: 0 0 0 60px rgba(28, 105, 194, 0);
    box-shadow: 0 0 0 var(--pulse-radius) var(--pulse-fade);
  }

  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform: scale(var(--pulse-scale));
            transform: scale(var(--pulse-scale));
    box-shadow: 0 0 0 0 rgba(28, 105, 194, 0);
    box-shadow: 0 0 0 0 var(--pulse-fade);
  }
}

@keyframes pulse {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform: scale(var(--pulse-scale));
            transform: scale(var(--pulse-scale));
    box-shadow: 0 0 0 0 rgba(28, 105, 194, 1);
    box-shadow: 0 0 0 0 var(--pulse-color);
  }

  95% {
    -webkit-transform: scale(1);
            transform: scale(1);
    box-shadow: 0 0 0 60px rgba(28, 105, 194, 0);
    box-shadow: 0 0 0 var(--pulse-radius) var(--pulse-fade);
  }

  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform: scale(var(--pulse-scale));
            transform: scale(var(--pulse-scale));
    box-shadow: 0 0 0 0 rgba(28, 105, 194, 0);
    box-shadow: 0 0 0 0 var(--pulse-fade);
  }
}

.client-side-table {
	height: 100%;
	min-height: 300px;
	width: 100%;
}
.tab-panel {
	margin: 15px;
	height: calc(100% - 50px - 40px);
}

.tab-buttons {
	/* min-width: 50px; */
}

.first-tab-button {
	/* max-width: 100%;
	width: 100%; */
}
.tab-buttons {
	/* min-width: 50px; */
}

.first-tab-button {
	/* max-width: 100%;
	width: 100%; */
}

.tab-panel {
	margin: 15px;
	height: 70vh;
}

/* If its a normal-ish type screen */
@media only screen and (max-width: 1336px) {
	.tab-panel {
		height: 65vh;
	}
}

/* If its a tablet-like screen */
@media only screen and (max-width: 1265px) {
	.tab-panel {
		height: 60vh;
	}
}

/* If its a mobile-like screen */
@media only screen and (max-width: 800px) {
	.tab-panel {
		height: 55vh;
	}
}
.user-table-container {
	height: 100%;
	min-height: 300;
	width: 100%;
}
.edit-container {
	height: calc(90vh - 15px);
	width: 100%;
}

.edit-view {
	height: calc(90vh - 13px);
	min-width: 500px;
	min-height: 500px;
	flex-grow: 0.60;
}

.container-padding {
	flex-grow: 0.25;
}

@media only screen and (max-width: 950px) {
	.edit-view {
		height: calc(90vh - 13px);
		min-width: 500px;
		min-height: 500px;
		flex-grow: 1;
	}
	.container-padding {
		flex-grow: 0.20;
		display: none;
	}
}
/* Multi View Contrainer */
.info-view {
	height: calc(90vh - 23px);
	min-width: 300px;
	min-height: 500px;
	flex-grow: 0.10;
}
.tiny-info-view {
	height: 81px;
	min-width: 300px;
	/* display: none; */
}
.additional-info-view {
	/* Setting the width somehow fixes the issue where the toolbar does not become scrollable.... (in UserGroupTabs) */
	width: 400px;
}
.create-view {
	height: calc(90vh - 13px);
	min-width: 500px;
	min-height: 500px;
	flex-grow: 0.50;
}
.tiny-info-hidden {
	display: none;
}
.info-hidden {
	display: none;
}
.additional-info-hidden {
	display: none;
}
.additional-info-normal
{
	flex-grow: 0.90;
	height: calc(90vh - 23px);
}
.additional-info-shorter {
	flex-grow: 1;
	height: calc(90vh - 130px);
}

/* If its a normal-ish type screen */
@media only screen and (max-width: 1336px) {
	.info-view {
		height: 90vh;
		min-width: 300px;
		min-height: 500px;
		/* flex-grow: 0.30; */
	}
	.additional-info-normal
	{
		height: 90vh;
		flex-grow: 0.90;
	}
	.additional-info-shorter {
		flex-grow: 1;
		height: calc(90vh - 100px);
	}
}

/* If its a tablet-like screen */
@media only screen and (max-width: 1265px) {
	.info-view {
		height: calc(90vh - 10px);
		min-width: 350px;
		min-height: 500px;
		/* flex-grow: 0.50; */
	}
	.additional-info-normal
	{
		flex-grow: 0.90;
		height: calc(90vh - 10px);
	}
	.additional-info-shorter {
		flex-grow: 1;
		height: calc(90vh - 110px);
	}
}

/* If its a mobile-like screen */
@media only screen and (max-width: 950px) {
	.info-view {
		height: calc(90vh - 15px);
		min-width: 500px;
		min-height: 500px;
		flex-grow: 1;
	}
	.edit-view {
		height: calc(90vh - 13px);
		min-width: 500px;
		min-height: 500px;
		flex-grow: 1;
	}
	.create-view {
		height: calc(90vh - 13px);
		min-width: 500px;
		min-height: 500px;
		flex-grow: 1;
	}
	.container-padding {
		flex-grow: 0.20;
		display: none;
	}
}

/* Single View Container */
.single-view-root {
    display: flex;
    flex-direction: row;
}
.single-view-container {
	height: calc(90vh - 15px);
	width: 100%;
}

.single-view {
	height: calc(90vh - 13px);
	min-width: 500px;
	min-height: 500px;
	flex-grow: 0.60;
}

.single-view-container-padding {
	flex-grow: 0.25;
}
.user-group-info-view {
	height: calc(90vh - 13px);
	min-width: 500px;
	min-height: 500px;
	flex-grow: 0.30;
}
.user-group-info {
	flex-grow: 0.70;
}

/* If its a normal-ish type screen */
@media only screen and (max-width: 1336px) {
	.user-group-info-view {
		height: 90vh;
		min-width: 500px;
		min-height: 500px;
		flex-grow: 0.30;
	}
}

/* If its a tablet-like screen */
@media only screen and (max-width: 1265px) {
	.user-group-info-view {
		height: calc(90vh - 10px);
		min-width: 500px;
		min-height: 500px;
		flex-grow: 0.50;
	}
	.user-group-info {
		flex-grow: 0.50;
	}
}

/* If its a mobile-like screen */
@media only screen and (max-width: 950px) {
	.user-group-info-view {
		height: calc(90vh - 15px);
		min-width: 500px;
		min-height: 500px;
		flex-grow: 1;
	}
	.user-group-info {
		display: none;
	}
}
.tiny-info-header {
	margin: 8px 0px 0px 0px
}

.tiny-info-container {
	min-width: 300;
	width: 100%;
	margin: 5px;
	position: relative;
}

.tiny-info-section {
	display: inline-block;
	margin-left: 15px;
}

.tiny-info-content {
	margin-left: 15px;
}
.user-group-table-container {
	height: 100%;
	min-height: 300;
	width: 100%;
}
.user-group-info-view {
	height: calc(90vh - 13px);
	min-width: 500px;
	min-height: 500px;
	flex-grow: 0.30;
}
.user-group-info {
	flex-grow: 0.70;
}

/* If its a normal-ish type screen */
@media only screen and (max-width: 1336px) {
	.user-group-info-view {
		height: 90vh;
		min-width: 500px;
		min-height: 500px;
		flex-grow: 0.30;
	}
}

/* If its a tablet-like screen */
@media only screen and (max-width: 1265px) {
	.user-group-info-view {
		height: calc(90vh - 10px);
		min-width: 500px;
		min-height: 500px;
		flex-grow: 0.50;
	}
	.user-group-info {
		flex-grow: 0.50;
	}
}

/* If its a mobile-like screen */
@media only screen and (max-width: 950px) {
	.user-group-info-view {
		height: calc(90vh - 15px);
		min-width: 500px;
		min-height: 500px;
		flex-grow: 1;
	}
	.user-group-info {
		display: none;
	}
}
.user-group-info-view {
	height: calc(90vh - 13px);
	min-width: 500px;
	min-height: 500px;
	flex-grow: 0.30;
}
.user-group-info {
	flex-grow: 0.70;
}

/* If its a normal-ish type screen */
@media only screen and (max-width: 1336px) {
	.user-group-info-view {
		height: 90vh;
		min-width: 500px;
		min-height: 500px;
		flex-grow: 0.30;
	}
}

/* If its a tablet-like screen */
@media only screen and (max-width: 1265px) {
	.user-group-info-view {
		height: calc(90vh - 10px);
		min-width: 500px;
		min-height: 500px;
		flex-grow: 0.50;
	}
	.user-group-info {
		flex-grow: 0.50;
	}
}

/* If its a mobile-like screen */
@media only screen and (max-width: 950px) {
	.user-group-info-view {
		height: calc(90vh - 15px);
		min-width: 500px;
		min-height: 500px;
		flex-grow: 1;
	}
	.user-group-info {
		display: none;
	}
}
.user-search {
	height: calc(90vh - 13px);
	min-width: 500px;
	min-height: 500px;
	flex-grow: 0.50;
}
.user-diff {
	height: calc(90vh - 13px);
	min-width: 500px;
	min-height: 500px;
	flex-grow: 0.50;
}

/* If its a normal-ish type screen */
@media only screen and (max-width: 1336px) {
	.user-search {
		height: 90vh;
		min-width: 500px;
		min-height: 500px;
		flex-grow: 0.50;
	}
	.user-diff {
		height: 90vh;
		min-width: 500px;
		min-height: 500px;
		flex-grow: 0.50;
	}
}

/* If its a tablet-like screen */
@media only screen and (max-width: 1265px) {
	.user-search {
		height: calc(90vh - 10px);
		min-width: 500px;
		min-height: 500px;
		flex-grow: 0.50;
	}
	.user-diff {
		height: calc(90vh - 10px);
		min-width: 500px;
		min-height: 500px;
		flex-grow: 0.50;
	}
}

/* If its a mobile-like screen */
@media only screen and (max-width: 950px) {
	.user-search {
		height: calc(90vh - 15px);
		min-width: 500px;
		min-height: 500px;
		flex-grow: 1;
	}
	.user-diff {
		display: none;
	}
}

.search {
	height: calc(90vh - 10px);
	min-width: 500px;
	min-height: 500px;
	flex-grow: 0.50;
	margin: 15px;
}

.diff {
	height: calc(90vh - 10px);
	min-width: 500px;
	min-height: 500px;
	flex-grow: 0.50;
	margin: 15px;
}
.vehicle-table-container {
	height: 100%;
	min-height: 300;
	width: 100%;
}
/* .vehicle-info-view {
	height: calc(90vh - 13px);
	min-width: 500px;
	min-height: 500px;
	flex-grow: 0.3;
}

.vehicle-info {
	flex-grow: 0.7;
}

@media only screen and (max-width: 1336px) {
	.vehicle-info-view {
		height: 90vh;
		min-width: 500px;
		min-height: 500px;
		flex-grow: 0.3;
	}
	.vehicle-info {
		width: 70%;
		flex-grow: 0.7;
	}
}


@media only screen and (max-width: 1265px) {
	.vehicle-info-view {
		height: calc(90vh - 10px);
		min-width: 500px;
		min-height: 500px;
		flex-grow: 0.3;
	}
	.vehicle-info {
		width: 100%;
		flex-grow: 0.7;
	}
}


@media only screen and (max-width: 800px) {
	.vehicle-info-view {
		height: calc(90vh - 15px);
		width: 100%;
		min-width: 500px;
		min-height: 500px;
		flex-grow: 1;
	}
	.vehicle-info {
		display: none;
		flex-grow: 0;
	}
} */


.vehicle-info-view {
	height: calc(90vh - 13px);
	min-width: 500px;
	min-height: 500px;
	flex-grow: 0.30;
}
.vehicle-info {
	flex-grow: 0.70;
}

/* If its a normal-ish type screen */
@media only screen and (max-width: 1336px) {
	.vehicle-info-view {
		height: 90vh;
		min-width: 500px;
		min-height: 500px;
		flex-grow: 0.30;
	}
}

/* If its a tablet-like screen */
@media only screen and (max-width: 1265px) {
	.vehicle-info-view {
		height: calc(90vh - 10px);
		min-width: 500px;
		min-height: 500px;
		flex-grow: 0.50;
	}
	.vehicle-info {
		flex-grow: 0.50;
	}
}

/* If its a mobile-like screen */
@media only screen and (max-width: 950px) {
	.vehicle-info-view {
		height: calc(90vh - 15px);
		min-width: 500px;
		min-height: 500px;
		flex-grow: 1;
	}
	.vehicle-info {
		display: none;
	}
}
.tab-buttons {
	/* min-width: 50px; */
}

.first-tab-button {
	/* max-width: 100%;
	width: 100%; */
}

.tab-panel {
	margin: 15px;
	height: 65vh;
}

/* If its a normal-ish type screen */
@media only screen and (max-width: 1336px) {
	.tab-panel {
		height: 65vh;
	}
}

/* If its a tablet-like screen */
@media only screen and (max-width: 1265px) {
	.tab-panel {
		height: 60vh;
	}
}

/* If its a mobile-like screen */
@media only screen and (max-width: 800px) {
	.tab-panel {
		height: 55vh;
	}
}

.tab-panel {
	margin: 15px;
	height: 70vh;
}

/* If its a normal-ish type screen */
@media only screen and (max-width: 1336px) {
	.tab-panel {
		height: 65vh;
	}
}

/* If its a tablet-like screen */
@media only screen and (max-width: 1265px) {
	.tab-panel {
		height: 60vh;
	}
}

/* If its a mobile-like screen */
@media only screen and (max-width: 800px) {
	.tab-panel {
		height: 55vh;
	}
}
.vehicle-table-container {
	height: 100%;
	min-height: 300;
	width: 100%;
}
.vehicle-group-table-container {
	height: 100%;
	min-height: 300;
	width: 100%;
}
.vehicle-group-info-view {
	height: calc(90vh - 13px);
	min-width: 500px;
	min-height: 500px;
	flex-grow: 0.30;
}
.vehicle-group-info {
	flex-grow: 0.70;
}

/* If its a normal-ish type screen */
@media only screen and (max-width: 1336px) {
	.vehicle-group-info-view {
		height: 90vh;
		min-width: 500px;
		min-height: 500px;
		flex-grow: 0.30;
	}
}

/* If its a tablet-like screen */
@media only screen and (max-width: 1265px) {
	.vehicle-group-info-view {
		height: calc(90vh - 10px);
		min-width: 500px;
		min-height: 500px;
		flex-grow: 0.50;
	}
	.vehicle-group-info {
		flex-grow: 0.50;
	}
}

/* If its a mobile-like screen */
@media only screen and (max-width: 950px) {
	.vehicle-group-info-view {
		height: calc(90vh - 15px);
		min-width: 500px;
		min-height: 500px;
		flex-grow: 1;
	}
	.vehicle-group-info {
		display: none;
	}
}
.tab-buttons {
	/* min-width: 50px; */
}

.first-tab-button {
	/* max-width: 100%;
	width: 100%; */
}
.tab-buttons {
	/* min-width: 50px; */
}

.first-tab-button {
	/* max-width: 100%;
	width: 100%; */
}

.tab-panel {
	margin: 15px;
	height: 70vh;
}

/* If its a normal-ish type screen */
@media only screen and (max-width: 1336px) {
	.tab-panel {
		height: 65vh;
	}
}

/* If its a tablet-like screen */
@media only screen and (max-width: 1265px) {
	.tab-panel {
		height: 60vh;
	}
}

/* If its a mobile-like screen */
@media only screen and (max-width: 800px) {
	.tab-panel {
		height: 55vh;
	}
}
.vehicle-group-table-container {
	height: 100%;
	min-height: 300;
	width: 100%;
}
.vehicle-group-table-container {
	height: 100%;
	min-height: 300;
	width: 100%;
}
.vehicle-search {
	height: calc(90vh - 13px);
	min-width: 500px;
	min-height: 500px;
	flex-grow: 0.50;
}
.vehicle-diff {
	height: calc(90vh - 13px);
	min-width: 500px;
	min-height: 500px;
	flex-grow: 0.50;
}

/* If its a normal-ish type screen */
@media only screen and (max-width: 1336px) {
	.vehicle-search {
		height: 90vh;
		min-width: 500px;
		min-height: 500px;
		flex-grow: 0.50;
	}
	.vehicle-diff {
		height: 90vh;
		min-width: 500px;
		min-height: 500px;
		flex-grow: 0.50;
	}
}

/* If its a tablet-like screen */
@media only screen and (max-width: 1265px) {
	.vehicle-search {
		height: calc(90vh - 10px);
		min-width: 500px;
		min-height: 500px;
		flex-grow: 0.50;
	}
	.vehicle-diff {
		height: calc(90vh - 10px);
		min-width: 500px;
		min-height: 500px;
		flex-grow: 0.50;
	}
}

/* If its a mobile-like screen */
@media only screen and (max-width: 950px) {
	.vehicle-search {
		height: calc(90vh - 15px);
		min-width: 500px;
		min-height: 500px;
		flex-grow: 1;
	}
	.vehicle-diff {
		display: none;
	}
}
.noselect {
	-webkit-touch-callout: none;	/* iOS Safari */
	-webkit-user-select: none;		/* Safari */		/* Konqueror HTML */			/* Old versions of Firefox */			/* Internet Explorer/Edge */
	user-select: none;				/* Non-prefixed version, currently
										supported by Chrome, Edge, Opera and Firefox */
}

.connection-status-container {
	z-index: 1;
	pointer-events: none;
	position: absolute;
	top: 10px;
	height: 35px;
	width: 100%;
}

.connection-status {
	z-index: 1;
	top: 10px;
	display: flex;
	pointer-events: auto;
	margin: auto;
	height: 35px;
	width: 180px;
	border-radius: 2px;

	align-items: center;
	justify-content: center;
}

.map-component {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
    border-radius: 2px;
    background-color: rgb(255, 255, 255);
}

.connection-status-text {
	margin-right: 7px;
}

.device-table-container {
	height: 100%;
	min-height: 300;
	width: 100%;
}
.device-table-container {
	height: 100%;
	min-height: 300;
	width: 100%;
}
.active > * {
    background-color: lightgray;
    color: black;
}
.qr-code-container {
    /* position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);

    width: calc(100% - 20px - 32px);
    height: calc(100% - 20px - 32px); */

    text-align: center;
    margin: auto;

    max-width: 512px;
    max-height: 512px;
}

.qr-code-header {
    /* position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);

    width: calc(100% - 20px - 32px);
    height: calc(100% - 20px - 32px); */

    text-align: center;
    /* margin: auto; */

}

.qr-code {
    /* display: inline; */

    width: 100% !important;
    height: auto !important;

    max-width: 512px;
    max-height: 512px;
}

.lessons-list-container {
	height: 100%;
	min-height: 300;
	width: 100%;
}
.lessons-list-container {
	height: 100%;
	min-height: 300;
	width: 100%;
}
.basic-grid {
    display: grid;
    grid-gap: 1rem;
    gap: 1rem;

    grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
}

.card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* background: #353535; */
    /* font-size: 3rem; */
    /* color: #fff; */
    box-shadow: rgba(3, 8, 20, 0.1) 0px 0.15rem 0.5rem, rgba(2, 8, 20, 0.1) 0px 0.075rem 0.175rem;
    height: 100%;
    width: 100%;
    border-radius: 4px;
    transition: all 500ms;
    overflow: hidden;

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
  
.card:hover {
    cursor: pointer;
    /* box-shadow: rgba(2, 8, 20, 0.1) 0px 0.35em 1.175em, rgba(2, 8, 20, 0.08) 0px 0.175em 0.5em;
    transform: translateY(-3px) scale(1.1); */
}
.pref-container {
	height: calc(90vh - 13px);
	width: 100%;
}

.pref-view {
	height: calc(90vh - 13px);
	min-width: 500px;
	min-height: 500px;
	flex-grow: 0.60;
}

.container-padding {
	flex-grow: 0.25;
}

@media only screen and (max-width: 950px) {
	.pref-view {
		height: calc(90vh - 13px);
		min-width: 500px;
		min-height: 500px;
		flex-grow: 1;
	}
	.container-padding {
		flex-grow: 0.20;
		display: none;
	}
}

.breadcrumb {
    text-decoration: none;
    color: grey;
}

.breadcrumb:hover {
    text-decoration: underline;
}
.device-table-container {
	height: 100%;
	min-height: 300;
	width: 100%;
}
.device-table-container {
	height: 100%;
	min-height: 300;
	width: 100%;
}
.company-table-container {
	height: 100%;
	min-height: 300;
	width: 100%;
}

.course-table-container {
	height: 100%;
	min-height: 300;
	width: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.user-search {
	height: calc(90vh - 13px);
	min-width: 500px;
	min-height: 500px;
	flex-grow: 0.50;
}
.user-diff {
	height: calc(90vh - 13px);
	min-width: 500px;
	min-height: 500px;
	flex-grow: 0.50;
}

/* If its a normal-ish type screen */
@media only screen and (max-width: 1336px) {
	.user-search {
		height: 90vh;
		min-width: 500px;
		min-height: 500px;
		flex-grow: 0.50;
	}
	.user-diff {
		height: 90vh;
		min-width: 500px;
		min-height: 500px;
		flex-grow: 0.50;
	}
}

/* If its a tablet-like screen */
@media only screen and (max-width: 1265px) {
	.user-search {
		height: calc(90vh - 10px);
		min-width: 500px;
		min-height: 500px;
		flex-grow: 0.50;
	}
	.user-diff {
		height: calc(90vh - 10px);
		min-width: 500px;
		min-height: 500px;
		flex-grow: 0.50;
	}
}

/* If its a mobile-like screen */
@media only screen and (max-width: 950px) {
	.user-search {
		height: calc(90vh - 15px);
		min-width: 500px;
		min-height: 500px;
		flex-grow: 1;
	}
	.user-diff {
		display: none;
	}
}

.search {
	height: calc(90vh - 10px);
	min-width: 500px;
	min-height: 500px;
	flex-grow: 0.50;
	margin: 15px;
}

.diff {
	height: calc(90vh - 10px);
	min-width: 500px;
	min-height: 500px;
	flex-grow: 0.50;
	margin: 15px;
}
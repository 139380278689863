.noselect {
	-webkit-touch-callout: none;	/* iOS Safari */
	-webkit-user-select: none;		/* Safari */
	-khtml-user-select: none;		/* Konqueror HTML */
	-moz-user-select: none;			/* Old versions of Firefox */
	-ms-user-select: none;			/* Internet Explorer/Edge */
	user-select: none;				/* Non-prefixed version, currently
										supported by Chrome, Edge, Opera and Firefox */
}

.connection-status-container {
	z-index: 1;
	pointer-events: none;
	position: absolute;
	top: 10px;
	height: 35px;
	width: 100%;
}

.connection-status {
	z-index: 1;
	top: 10px;
	display: flex;
	pointer-events: auto;
	margin: auto;
	height: 35px;
	width: 180px;
	border-radius: 2px;

	align-items: center;
	justify-content: center;
}

.map-component {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
    border-radius: 2px;
    background-color: rgb(255, 255, 255);
}

.connection-status-text {
	margin-right: 7px;
}

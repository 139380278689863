.edit-container {
	height: calc(90vh - 15px);
	width: 100%;
}

.edit-view {
	height: calc(90vh - 13px);
	min-width: 500px;
	min-height: 500px;
	flex-grow: 0.60;
}

.container-padding {
	flex-grow: 0.25;
}

@media only screen and (max-width: 950px) {
	.edit-view {
		height: calc(90vh - 13px);
		min-width: 500px;
		min-height: 500px;
		flex-grow: 1;
	}
	.container-padding {
		flex-grow: 0.20;
		display: none;
	}
}
.info-header {
	margin: 8px 0px 0px 0px
}

.center-profile-image {
	display: block;
	margin-left: auto;
	margin-right: auto;
} 

.border-profile-image {
	border: 3px solid #3498db;
	border-radius: 10%;
}

.size-profile-image {
	width: 150px;
	height: 150px;
}

.size-profile-icon {
    font-size: 150px !important;
}

.container {
    width: 157px;
    height: 157px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.basic-info-item-header {
    margin: 0px;
    color: grey;
    font-weight: normal;
}

.basic-info-item-data {
    margin-top: 1px;
    margin-bottom: 5px;
    font-weight: normal;
}

.tab-buttons {
	/* min-width: 50px; */
}

.first-tab-button {
	/* max-width: 100%;
	width: 100%; */
}

.tab-panel {
	margin: 15px;
	height: 65vh;
}

/* If its a normal-ish type screen */
@media only screen and (max-width: 1336px) {
	.tab-panel {
		height: 65vh;
	}
}

/* If its a tablet-like screen */
@media only screen and (max-width: 1265px) {
	.tab-panel {
		height: 60vh;
	}
}

/* If its a mobile-like screen */
@media only screen and (max-width: 800px) {
	.tab-panel {
		height: 55vh;
	}
}
.qr-code-container {
    /* position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);

    width: calc(100% - 20px - 32px);
    height: calc(100% - 20px - 32px); */

    text-align: center;
    margin: auto;

    max-width: 512px;
    max-height: 512px;
}

.qr-code-header {
    /* position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);

    width: calc(100% - 20px - 32px);
    height: calc(100% - 20px - 32px); */

    text-align: center;
    /* margin: auto; */

}

.qr-code {
    /* display: inline; */

    width: 100% !important;
    height: auto !important;

    max-width: 512px;
    max-height: 512px;
}
